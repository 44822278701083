const galleries = document.querySelectorAll('.cb-gallery');  

Array.from(galleries).forEach(gallery => {
    const galleryForOverlay = gallery.querySelectorAll('.js-fullscreen-takeover-image-wrapper');
    const captionData = gallery.querySelectorAll('.js-fullscreen-takeover-caption');
    let captions = [];
    const galleryItems = gallery.querySelectorAll('.js-gallery-image-wrapper');

    Array.from(captionData).forEach(caption => {
        captions.push(caption.innerHTML); 
    });

    attachFullScreenEvent();

    function attachFullScreenEvent() {
        Array.from(galleryItems).forEach(preview => {
            preview.addEventListener('click', (event) => {
                window.FullscreenTakeover.openGallery(galleryForOverlay, parseInt(event.target.offsetParent.dataset.index), captions);
            })
        });
    }
});